import { page } from '$app/stores'
import { derived } from 'svelte/store'
import type { PanelType } from '$lib/utils/panel'

export interface URLState {
	tripId: string | null
	panel: PanelType | null
	parentBlockId: string | null
	itineraryId: string | null
	isHighlights: boolean
	expandedBlockId: string | null
	isPublic: boolean
	isProfile: boolean
	blockId: string | null
	activeBlockId: string | null
	requiresAuthentication: boolean
	discountCode: string | null
	isReview: boolean
	isListing: boolean
}

// Create a derived store from the page store
const urlState = derived<typeof page, URLState>(page, ($page) => {
	const url = new URL($page.url)

	return {
		tripId: $page.params.slug ?? null,
		panel: url.searchParams.get('panel') as PanelType | null,
		parentBlockId: url.searchParams.get('parent'),
		itineraryId: url.searchParams.get('itinerary'),
		activeBlockId: url.searchParams.get('active'),
		expandedBlockId: url.searchParams.get('expanded'),
		isHighlights: $page.url.pathname.endsWith('/highlights'),
		isPublic: $page.url.pathname.startsWith('/explore'),
		isReview: $page.url.pathname.startsWith('/review'),
		blockId: $page.params.block ?? null,
		isProfile: $page.url.pathname.startsWith('/profile'),
		requiresAuthentication: $page.data.requiresAuthentication ?? false,
		discountCode: url.searchParams.get('code') ?? null,
		isListing: $page.url.pathname.endsWith('/listing'),
	}
})

export { urlState }
